import React from 'react'

const CloseIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3.19983" y="0.899536" width="19.5166" height="3.25277" rx="1.62638" transform="rotate(45 3.19983 0.899536)" fill="#010206"/>
        <rect x="17" y="3.19958" width="19.5166" height="3.25277" rx="1.62638" transform="rotate(135 17 3.19958)" fill="#010206"/>
    </svg>
  )
}

export default CloseIcon